import type { ShortIssueWithId } from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'
import type { UpdatedIssue } from '~/components/Issue/Table/types'

type IssueVariant = ShortIssueWithId | UpdatedIssue | null

export function useIssueInvestedPercent(issueRef: IssueVariant | Ref<IssueVariant>): number {
  const issue = unref(issueRef)

  const areDataAvailable = computed(() => issue?.availableCount !== null && issue?.totalAmount !== null)

  const availableAmount = computed(() => {
    if (!areDataAvailable.value) {
      return null
    }

    const nominalValue = typeof issue?.nominalValue === 'number'
      ? issue.nominalValue
      : (issue?.nominalValue?.value || 0)

    return (issue?.availableCount || 0) * nominalValue
  })

  const investedAmount = computed(() => {
    if (!issue?.totalAmount || availableAmount.value === null) {
      return null
    }

    if (availableAmount.value === 0) {
      return issue?.totalAmount
    }

    return issue?.totalAmount - availableAmount.value
  })

  const investedPercent = computed(() => areDataAvailable.value ? (investedAmount.value! / (issue?.totalAmount || 1)) : 0)

  return Math.floor(investedPercent.value * 100)
}
